<template>
    <div>
        <canvas v-if="!isLoading" ref="chart" id="tabeo-chart" width="400" height="300"></canvas>
        <div v-if="isLoading" class="d-flex flex-row flex-fill align-items-center justify-content-center">
            <b-spinner variant="secondary"></b-spinner>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default {
    props: {
        data: {
            required: true,
        },
        isLoading: {
            required: true,
            type: Boolean,
        },
        type: {
            required: false,
            default: 'pie'
        }
    },
    data: () => ({
        chart: null,
        chartConf: {
            type: 'pie',
            data: [],
        },
        debouncer: null,
    }),
    created() { this.chartConf.type = this.type },
    watch: {
        data() { this.updateChart() },
        isLoading() { this.updateChart() }
    },
    methods: {
        updateChart() {
            this.chartConf.data = this.data
            clearTimeout(this.debouncer)
            this.debouncer = setTimeout(() => {
                window.requestAnimationFrame(() => {
                    if(!this.chart && !this.isLoading) {
                        var chart = new Chart(
                            this.$refs['chart'],
                                {...this.chartConf});
                    }
                })
            }, 100)
            
        }
    },
}
</script>