<template>
  <b-container fluid>

    

    <b-row class="mb-4">
      <b-col class="mb-3 mb-md-0" md="4">
        <h1 class="font-weight-bold mb-0 text-primary">Tabeo Report</h1>
      </b-col>
      <b-col class="mb-3 mb-md-0" md="8">
        <DateRangePicker :from="fromDate" :to="toDate" @input="input => { this.fromDate = input.date1; this.toDate = input.date2; this.doFetchTabeoReport() }"></DateRangePicker>
      </b-col>
    </b-row>

    <b-row>
        <b-col cols="12" md="4" class="mt-4 mt-md-0">
            <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
                <h4 class="mb-3 text-center">Successful</h4>
                <h2 class="mb-0 text-muted" v-if="!isLoading">{{ single.totals.success }}</h2>
                <b-spinner v-if="isLoading" variant="secondary"></b-spinner>
            </b-card>
        </b-col>
        <b-col cols="12" md="4" class="mt-4 mt-md-0">
            <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
                <h4 class="mb-3 text-center">Pending</h4>
                <h2 class="mb-0 text-muted" v-if="!isLoading">{{ single.totals.pending }}</h2>
                <b-spinner v-if="isLoading" variant="secondary"></b-spinner>
            </b-card>
        </b-col>
        <b-col cols="12" md="4" class="mt-4 mt-md-0">
            <b-card no-body class="d-flex flex-column align-items-center justify-content-center shadow-sm p-4">
                <h4 class="mb-3 text-center">Unsuccessful</h4>
                <h2 class="mb-0 text-muted" v-if="!isLoading">{{ single.totals.unsuccessful }}</h2>
                <b-spinner v-if="isLoading" variant="secondary"></b-spinner>
            </b-card>
        </b-col>
    </b-row>

    <b-row class="mt-4">
        <b-col cols="6">
            <b-card>
                <h4>Tabeo Statuses</h4>
                <hr>
                <TabeoChart :data="single.pieChartData" :isLoading="isLoading"/>
            </b-card>
        </b-col>
        <b-col cols="6">
            <b-card>
                <h4>Tabeo Statuses</h4>
                <hr>
                <TabeoChart type="line" :data="single.lineChartData" :isLoading="isLoading"/>
            </b-card>
        </b-col>
    </b-row>
  </b-container>
</template>



<script>
import currentUser from '../../mixins/currentUser'
import momentMixin from '../../mixins/momentMixin'
import DateRangePicker from '../../components/DateRangePicker'
import { mapActions, mapGetters } from 'vuex'
import TabeoChart from '../../components/Charts/TabeoChart'

export default {
  mixins: [currentUser, momentMixin],
  components: {
      DateRangePicker,
      TabeoChart
  },
  data: () => ({
    fromDate: null,
    toDate: null
  }),
  created () {
    this.fromDate = this.moment().toDate()
    this.toDate = this.moment().subtract(1, 'month').toDate()
    this.doFetchTabeoReport()
  },
  computed: {
    ...mapGetters('reports', ['single', 'isLoading'])
  },
  methods: {
    ...mapActions('reports', ['fetchTabeoReport']),

    doFetchTabeoReport() {
        this.fetchTabeoReport({
            from: this.moment(this.fromDate).format('DD/MM/YYYY'),
            to: this.moment(this.toDate).format('DD/MM/YYYY')
        })
    }
  }
}
</script>

<style>

</style>
